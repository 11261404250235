




import Vue from "vue";
import MenuButton from "../MenuButton.vue";
import { CrewMemberModel } from '../../../../api/generated/api';
import Api from "@/api";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import { AxiosResponse } from 'axios';

export default Vue.extend({
  props: ['item', 'crewMemberVesselEnquiry'],
  components: {
    MenuButton
  },
  methods: {
    async onDownloadGdprForm() {
      try {
        const item = this.item as CrewMemberModel;

        this.$emit('loading', true);

        let response : any; 

        if (this.crewMemberVesselEnquiry != null) {
          response = await Api.ReportService.apiReportGdprAkanEnquiryIdGet(
            this.crewMemberVesselEnquiry.crewMemberVesselEnquiryId
          );
        } else if (item.crewMemberId != null) {
          response = await Api.ReportService.apiReportGdprAkanCrewIdGet(
            item.crewMemberId
          );
        }

        FileDownloadUtility.DownloadFile(
          response.data,
          `${item?.fullname ? item.fullname : this.crewMemberVesselEnquiry.crewMemberFullname} GDPR Akan.docx`
        );
      } finally {
        this.$emit('loading', false);
      }
    }
  }
});

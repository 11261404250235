










import Vue from "vue";
import GDPRMenuItem from '@/components/common/menu-items/GDPR/GDPRMenuItem.vue';
import GDPRAkanMenuItem from '@/components/common/menu-items/GDPR/GDPRAkanMenuItem.vue';
import GDPRHindiMenuItem from "../GDPR/GDPRHindiMenuItem.vue";
import GDPRSinhalaMenuItem from "../GDPR/GDPRSinhalaMenuItem.vue";
import GDPRTagalogMenuItem from "../GDPR/GDPRTagalogMenuItem.vue";
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);

export default Vue.extend({
  props: ['item', 'crewMemberVesselEnquiry'],
  components: {
    GDPRMenuItem,
    GDPRAkanMenuItem,
    GDPRHindiMenuItem,
    GDPRSinhalaMenuItem,
    GDPRTagalogMenuItem
  },
  methods: {
    handleLoadingChanged(isLoading: boolean) {
      this.$emit('loading', isLoading);
    }
  },
  computed: {
    agencyAdminRole() {
      return userModule.role == "AGM";
    }
  },
});
